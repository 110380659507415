import css from '../GMap.module.scss';
import React, { FC, HTMLAttributes, useRef } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { MapObject, Box, GMapWidget } from '@core';

export interface GMapProps extends HTMLAttributes<HTMLDivElement> {
	objects: MapObject[];
	defaultZoom?: number;
	defaultCenter?: [number, number];
	setCenterOnActive?: boolean;
}

export const GMapComponent: FC<GMapProps> = ({
	objects,
	defaultZoom = 15,
	defaultCenter = [55.755819, 37.617644],
	setCenterOnActive,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: 'AIzaSyCst4ADDhi6zXBi01xexWZOThpx4yzFshk',
	});

	return (
		<>
			{isLoaded && (
				<Box ref={ref} className={css.container}>
					<GMapWidget
						ref={ref}
						objects={objects}
						defaultZoom={defaultZoom}
						defaultCenter={defaultCenter}
						setCenterOnActive={setCenterOnActive}
					/>
				</Box>
			)}
		</>
	);
};
